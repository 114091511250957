.block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.labels {
  margin-bottom: 10px;
  word-break: break-word;
}

.tag {
  margin-bottom: 5px;
  white-space: normal !important;
}
