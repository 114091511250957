@import "../../assets/styles/global.scss";

.editor {
  min-width: 320px;
  margin: 0 auto;

  @include respond("phone") {
    width: 300px;
    min-width: 300px;
  }
}

.menu {
  width: 320px;
  min-width: 320px;
  display: flex;
  flex-flow: column;

  & > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include respond("phone") {
    margin-left: 0;
    margin-top: 20px;
  }
}

.menu:empty {
  width: 0;
  min-width: auto;
}

.content {
  border: 1px solid #e8e8e8;
  padding: 10px 20px;

  @media (max-width: 640px) {
    padding: 0px;
    border: 0;
  }
}

.common {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  .common {
    display: initial !important;
  }
}
