@import "~antd/dist/antd.css";

@import "./variables";
@import "./mixins";
@import "./functions";

.ls-renderall {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ls-renderall > div {
  width: 49%;
  margin-right: 1%;
}

.ls-fade {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
