@import "../../assets/styles/global.scss";

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @include respond("phone") {
    flex-direction: column;
  }
}

.block {
  max-width: 320px;

  &__controls {
    width: 940px;
    max-width: 940px;

    @media screen and (max-width: 1400px) {
      max-width: 800px;
    }

    @media screen and (max-width: 1280px) {
      max-width: 750px;
    }

    @include respond("phone") {
      width: 100%;
    }
  }

  & > button {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include respond("phone") {
      margin-bottom: 10px;
      margin: 0 2px;
      margin-bottom: 10px;
    }
  }
}
