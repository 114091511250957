.list {
  max-height: 350px;
  overflow-y: scroll;
}

.item {
  color: get-color(error);
  font-size: 14px;

  &:hover {
    cursor: pointer;
    color: #f5222d;
  }
}
