.progress {
  color: #ff5630;
}

.wave {
  position: relative;
}

.menu {
  margin: 2em 0;
}
